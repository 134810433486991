:root {
  --app-color-brand: #E507DC;
  --app-color-brand-highlight: #eaec42;
  --app-color-brand-dark: #e0b437;
  --app-color-brand-light: #e0b437;

  --app-color-brand-accent: #1abfc6;
  --app-color-brand-accent-highlight: #2fdce4;
  --app-color-brand-accent-dark: #142127;
  --app-color-brand-accent-light: #1c2f38;

  --app-color-off-white: #fff;

  --app-link-color: #16F87F;

  --app-background: linear-gradient(330deg, #220939, #0b2f44);

  --app-header-height: 56px;
  --app-header-background: var(--app-color-neutral-white);
  --app-header-color: var(--app-color-off-white);

  --app-color-neutral-white: #101023;
  --app-color-neutral: #fff;
  --app-color-neutral-darker: var(--app-color-neutral);

  --app-color-shadow-line: hsla(189, 9%, 15%, 0.15);
  --app-shadow-low: 0 1px 4px var(--app-color-shadow-line);
  --app-shadow-mid: 0 2px 4px var(--app-color-shadow-line);
  --app-shadow-high: 0 5px 10px var(--app-color-shadow-line);
  --app-shadow-inset-low: inset 0 1px 4px var(--app-color-shadow-line);
  --app-shadow-inset-mid: inset 0 2px 4px var(--app-color-shadow-line);
  --app-shadow-inset-high: inset 0 5px 10px var(--app-color-shadow-line);
}

@font-face {
  font-family: texts;
  font-display: swap;
  src:  url('../fonts/exo/Exo-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: headlines;
  font-display: swap;
  src:  url('../fonts/exo/Exo-VariableFont_wght.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  text-rendering: optimizeLegibility;
}

body {
  background: var(--app-background);
}

body, input, textarea, select, button {
  font-family: texts, Arial, Helvetica, sans-serif;
  font-size: 1em;
  line-height: 1.5;
}

h1, h2, h3 {
  font-family: headlines, Arial, Helvetica, sans-serif;
  letter-spacing: -.03em;
  line-height: 1.1;
}
body a {
  color: var(--app-link-color);
}
header {
  color: var(--app-header-color);
}
header button {
  color: var(--app-header-color);
}
.app-content__column {
  border-top: 6px solid #E507DC;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
  border-image-source: linear-gradient(45deg, #E507DC, #8160F4, #44ADF6);
  border-image-slice: 1 0;
  border-radius: 2px;
} 
.app-logo::after {
  content: url('../svgs/logo-x3000.svg');
  width: 5.5em;
  margin-top: .5em;
}